import React from 'react'
import "./segmentCare.css"
import IconChevronRight from "../../images/vector-61.svg"
import PlaceholderImage from "../../images/healthAndCareSegment.png"

export default function SegmentCare() {
  return (
    <div>
      <div className="HealthCareSegment-layout-4">
            <div className="HealthCareSegment-container3">
              <div className="HealthCareSegment-content5">
                <div className="HealthCareSegment-content6">
                  <div className="HealthCareSegment-section-title">
                    <div className="HealthCareSegment-content4">
                      <div className="HealthCareSegment-heading">{`Health & Personal Care`}</div>
                      <div className="HealthCareSegment-text1">{`The Health & Personal Care sector includes baby care, herbal care, pharmaceuticals, medical devices and personal care. A unique blend of indigenous and globally reputed brands ensures that this sector remains relevant to changing consumer preferences and across many market segments.`}</div>
                    </div>
                  </div>
                </div>
                <div className="HealthCareSegment-actions2">
                  <div className="HealthCareSegment-button3">
                    <div className="HealthCareSegment-button-child" />
                    <div className="HealthCareSegment-read-more">Read More</div>
                    <img className="HealthCareSegment-button-item" alt="" src={IconChevronRight} />
                  </div>
                </div>
              </div>
              <img
                className="HealthCareSegment-placeholder-image-icon"
                alt=""
                src={PlaceholderImage}
              />
            </div>
          </div>
    </div>
  )
}
