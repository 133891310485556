import React from 'react'
// import Aos from "aos";
// import "aos/dist/aos.css";
import "./segmentAgriProduce.css"
import IconChevronright from "../../images/vector-6.svg"
import PlaceholderImage from "../../images/SegmentAgriImage.png"

export default function SegmentAgriProduce() {

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  // }, []);
  
  return (
    <div>
      <div className="segmentAgri-layout-192">
            <div className="segmentAgri-container2">
              <img
                className="segmentAgri-placeholder-image-icon"
                alt=""
                src={PlaceholderImage}
              />
              <div className="segmentAgri-content3">
                <div className="segmentAgri-section-title">
                  <div className="segmentAgri-content4">
                    <div className="segmentAgri-heading">Agri Produce</div>
                    <div className="segmentAgri-text1">
                      CIC is the only company in Sri Lanka that manages the
                      entire supply chain from seed to shelf connecting rural
                      farmers to urban consumers and facilitating the socio
                      economic progress of rural communities with the guarantee
                      of CIC quality.
                    </div>
                  </div>
                </div>
                <div className="segmentAgri-actions2">
                  <div className="segmentAgri-button3">
                    <div className="segmentAgri-button-child" />
                    <div className="segmentAgri-read-more">Read More</div>
                    <img className="segmentAgri-button-item" alt="" src={IconChevronright} />
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
