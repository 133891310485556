import React from 'react'
import "./segmentCrop.css"
import PlaceholderImage1 from "../../images/cropSegmentImage1.png"
import CropSegVector from "../../images/vector-61.svg"

export default function SegmentCrop() {

  return (
    <div>
      <div className="cropSegment-layout-4">
            <div className="cropSegment-container3">
              <div className="cropSegment-content5">
                <div className="cropSegment-content6">
                  <div className="cropSegment-section-title">
                    <div className="cropSegment-content4">
                      <div className="cropSegment-heading">Crop Solutions</div>
                      <div className="cropSegment-text1">
                        Crop Solutions includes plant nutrition, plant
                        protection, seeds and lawn and garden clusters that
                        plays a vital role in supporting the country’s
                        agriculture sector aiding yield maximization and
                        resilience against adverse factors.
                      </div>
                    </div>
                  </div>
                  <div className="cropSegment-list">
                    <div className="cropSegment-list-item">
                      <div className="cropSegment-text3">
                        Farm-fresh fruits, vegetables, and grains that are
                        packed with nutrients and flavor.
                      </div>
                    </div>
                    <div className="cropSegment-list-item">
                      <div className="cropSegment-text3">
                        Dependable supply chain ensuring consistent availability
                        and timely delivery of our products.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cropSegment-actions2">
                  <div className="cropSegment-button3">
                    <div className="cropSegment-button-child" />
                    <div className="cropSegment-read-more">Read More</div>
                    <img className="cropSegment-button-item" alt="" src={CropSegVector} />
                  </div>
                </div>
              </div>
              <img
                className="cropSegment-placeholder-image-icon"
                alt=""
                src={PlaceholderImage1}
              />
            </div>
          </div>
    </div>
  )
}
