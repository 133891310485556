import React from 'react'
import "./segmentIndustrial.css"
import PlaceHolder from "../../images/industrySolution.png"
import ChevronRight from "../../images/vector-6.svg"


export default function SegmentIndustrial() {
  return (
    <div>
      <div className="IndustrySolSegment-layout-192">
            <div className="IndustrySolSegment-container2">
              <img
                className="IndustrySolSegment-placeholder-image-icon"
                alt=""
                src={PlaceHolder}
              />
              <div className="IndustrySolSegment-content3">
                <div className="IndustrySolSegment-section-title">
                  <div className="IndustrySolSegment-content4">
                    <div className="IndustrySolSegment-heading">Industrial Solutions</div>
                    <div className="IndustrySolSegment-text1">
                      The Industrial Solutions sector includes construction
                      material, industrial raw material, water treatment and
                      packaging. Strong relationships and island wide
                      distribution networks support the growth of this lucrative
                      sector supported by an increasingly wide portfolio of
                      products.
                    </div>
                  </div>
                </div>
                <div className="IndustrySolSegment-actions2">
                  <div className="IndustrySolSegment-button3">
                    <div className="IndustrySolSegment-button-child" />
                    <div className="IndustrySolSegment-read-more">Read More</div>
                    <img className="IndustrySolSegment-button-item" alt="" src={ChevronRight} />
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
