import React from 'react'
// import Aos from "aos";
// import "aos/dist/aos.css";
import "./segmentOverview.css"


export default function SegmentOverview() {

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  // }, []);

  // <div className="segmentOverview-tab-horizontal" data-aos="fade-left" data-aos-duration="2000"></div>

  return (
    <div>
      <div className="segmentOverview-layout-42">
            <div className="segmentOverview-content2">
              <div className="segmentOverview-column2">
                <div className="segmentOverview-heading">
                  CIC has clustered its business ventures into five distinct
                  segments
                </div>
              </div>
              <div className="segmentOverview-column3">
                <div className="segmentOverview-text">
                  <p className="segmentOverview-this-was-done">
                    This was done not only as a management method, but a
                    simplified framework that will benefit us in the short term
                    as well as the long term.
                  </p>
                  <p className="segmentOverview-this-was-done">&nbsp;</p>
                  <p className="segmentOverview-this-was-done">
                    CIC is a name synonymous with the upliftment of agriculture
                    in Sri Lanka and this is the reason that three of its
                    segments – Agri Produce, Crop Solutions, and Livestock
                    Solutions – are dedicated to this major industry. CIC’s
                    health and personal care segment counts some of its newer
                    ventures, while the Industrial Solutions segment includes
                    some of its first. All segments, therefore, bear great value
                    to CIC as a conglomerate as a way to focus on strategy and
                    individually, as points of pride.
                  </p>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
