import React from 'react'
import "./segmentLivestock.css"
import IconChevronRight from "../../images/vector-6.svg" 
import PlaceholderImage from "../../images/liveStockSegment.png"

export default function SegmentLivestock() {
  return (
    <div>
      <div className="LivestockSegment-layout-192">
            <div className="LivestockSegment-container2">
              <img
                className="LivestockSegment-placeholder-image-icon"
                alt=""
                src={PlaceholderImage}
              />
              <div className="LivestockSegment-content3">
                <div className="LivestockSegment-section-title">
                  <div className="LivestockSegment-content4">
                    <div className="LivestockSegment-heading">Livestock Solutions</div>
                    <div className="LivestockSegment-text1">
                      The CIC Livestock Solutions sector is a key player in the
                      field, synonymous with quality and expertise in the
                      industry.
                    </div>
                  </div>
                </div>
                <div className="LivestockSegment-actions2">
                  <div className="LivestockSegment-button3">
                    <div className="LivestockSegment-button-child" />
                    <div className="LivestockSegment-read-more">Read More</div>
                    <img className="LivestockSegment-button-item" alt="" src={IconChevronRight} />
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
